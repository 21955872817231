import { makeStyles } from '@material-ui/core/styles';
// import MoneyEnvelope from '../../images/MoneyEnvelope.jpeg';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    padding: theme.spacing(2),
    margin: '30px ',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: 'md',
    alignItems: "center",
    // height: 400,
  },

  main: {
    backgroundColor: theme.palette.grey[900],
  },
  main2: {
    // backgroundColor: theme.palette.grey[200],
  },

  headline: {
    padding: '30px'
  },

  paperHighlight: {
    backgroundColor: theme.palette.grey[900],
    margin: '10px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: '30px',
    margin: '10px',
    alignItems: "center"
  },

  containerDiv: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  mediaCard: {
    maxWidth: '100%',
    height: 400,
    margin: '10px 30px 30px 30px',
  },
  media: {
    height: 140,
  },
  media2: {
    height: 300,
    padding: '0 10px 0 10px',
  },
  cardHeader: {
    padding: '30px',
    margin: '30px',
    backgroundColor: '#013029',
    color: 'white',
  },
  cardHeader1: {
    padding: '30px',
    margin: '30px',
    // backgroundColor: '#080d30',
    backgroundColor: '#010f75',
    color: 'white',
  },
  cardHeader3: {
    padding: '10px',
    margin: '10px',
    width: '80%',
    // backgroundColor: '#080d30',
    backgroundColor: theme.palette.grey[900],
    color: 'white',
  },
  area1: {
    // backgroundColor: '#1d2973',
    // backgroundColor: theme.palette.grey[900],
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    // color: 'white',
  },
  area2: {
    backgroundColor: '#004d40',
    
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    // color: 'white',
  },
  contentCard: {
    padding: '30px',
    margin: '30px',
    // backgroundColor: '#01826d',
    // color: 'white',
    alignItems: "center",
    height: 300,
   
  },
  contentCard1: {
    padding: '30px',
    margin: '30px',
    // backgroundColor: '#576bfa',
    // color: 'white',
    alignItems: "center"
   
  },
  contentCard3: {
    padding: '30px',
    margin: '5px',
    backgroundColor:  theme.palette.grey[900],
    color: 'white',
    alignItems: "center",
   
  },
infoCard: {
    padding: '30px',
    margin: '30px',
    // backgroundColor: '#01826d',
    // color: 'white',
    display: 'flex',
    // flexWrap: 'wrap',
    alignItems: "center",
    height: 300,
    
  },
  infoContent: {
    padding: '30px',
    alignItems: "center",
    // height: 200
  },
  infoContent2: {
    padding: '40px 0px 50px',
    alignItems: "center",
    // height: 200
  },
  divider: {
    margin: '0 10px',
  }

}));