import { GET_ACCOUNTS, ACCOUNT_ERROR, UPDATE_ACCOUNT,DELETE_ACCOUNT } from '../actions/types'

const initialState = {
    accounts: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                accounts: payload,
                error: null,
                loading: false
            };
        case UPDATE_ACCOUNT:
        return {
            ...state,
            account: payload,
            error: null,
            loading: false
        };
        case DELETE_ACCOUNT:
        return {
            ...state,
            account: payload,
            error: null,
            loading: false
        };
        case ACCOUNT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
                return state;
    }

}