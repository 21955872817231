import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import Box from '@material-ui/core/Box';
// import Container from '@material-ui/core/Container';
import trimtabLogoBlack from '../../images/trimtabLogoBlack.png';
// import IconButton from '@material-ui/core/IconButton';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
import { Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth'



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "flex",
      flexGrow: 1,
      marginRight: theme.spacing(2),
      textAlign: "left"
    },
    appBar: {
        background: 'white',
        // color: "#565656",
      },
      button: {
        marginRight: theme.spacing(2),
      },
  }));

// function ElevationScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 4,
//   });
// }

// ElevationScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

// export default function MainAppBar(props) {

//     const classes = useStyles();

//     const [auth, setAuth] = React.useState(true);

//     const [anchorEl, setAnchorEl] = React.useState(null);

//     const open = Boolean(anchorEl);

//     const handleChange = (event) => {
//         setAuth(event.target.checked);
//       };

//       const handleClose = () => {
//         setAnchorEl(null);
//       };

//       const handleMenu = (event) => {
//         setAnchorEl(event.currentTarget);
//       };



//   return (
//       <div>
//       <CssBaseline />
//       <ElevationScroll {...props}>
//         <AppBar className={classes.appBar} >
//           <Toolbar>
//           <img src={trimtabLogoBlack} alt="Trimtab logo" width="25%" ></img>
//             <Typography className={classes.title} variant="h5"></Typography>
//             {!auth ? (
//             <div>
//               <IconButton
//                 aria-label="account of current user"
//                 aria-controls="menu-appbar"
//                 aria-haspopup="true"
//                 onClick={handleMenu}
//                 color="inherit"
//               >
//                 <AccountCircle />
//               </IconButton>
//               <Menu
//                 id="menu-appbar"
//                 anchorEl={anchorEl}
//                 anchorOrigin={{
//                   vertical: 'top',
//                   horizontal: 'right',
//                 }}
//                 keepMounted
//                 transformOrigin={{
//                   vertical: 'top',
//                   horizontal: 'right',
//                 }}
//                 open={open}
//                 onClose={handleClose}
//               >
//                 <MenuItem onClick={handleClose}>Profile</MenuItem>
//                 <MenuItem onClick={handleClose}>My account</MenuItem>
//               </Menu>
//             </div>) :
//             <div>
//             <Button className={classes.button} variant="outlined" size="small" color="white" href="/Register" >Register</Button>
//             <Button className={classes.button} variant="outlined" size="small" color="white" href="/Login" >Login</Button>
//             </div>
//             }

//           </Toolbar>
//         </AppBar>
//       </ElevationScroll>
//       <Toolbar />
//     </div>
//   );
// }

const MainAppBar = ({ auth: { isAuthenticated, loading }, logout }) => {
  const classes = useStyles();

  


  const authLinks = (
    
    <ul>
        {/* <li><Link to='/profiles'>
          Developers
          </Link>
        </li>
        <li><Link to='/dashboard'>
            <i className="fas fa-user"></i>{'  '}
            <span className='hide-sm'>Dashboard</span></Link></li>
        <li><a onClick={logout} href='#!'><i className="fas fa-sign-out-alt"></i>{'  '} <span className='hide-sm'>Logout</span></a></li> */}
        {/* <Link to={'/accounts'} className='btn btn-primary'>
                    Accounts
        </Link> */}
        <li><Button className="button" variant="outlined" size="small" color="primary" href="/Dashboard" >Dashboard</Button></li>

        <li><Button className="button" variant="outlined" size="small" color="primary" href="/" onClick={logout} >Logout</Button></li>
      </ul>
  );

  const guestLinks = (
    <ul>
        {/* <li><Link to='/profiles'>
          Developers
          </Link>
        </li> */}
        {/* <li><Button className="button" variant="outlined" size="small" color="primary" href="/Register" >Register</Button></li> */}
        
        <li><Button className="button" variant="outlined" size="small" color="primary" href="/Login" >Login</Button></li>
        
      </ul>
  )


    return (
    <nav className="navbar bg-dark">
    <Toolbar>
      <h1 className={classes.title}>
        <Link  to='/'><img src={trimtabLogoBlack} alt="Trimtab logo" width="25%" ></img></Link>
      </h1>
      { !loading && (<Fragment>{ isAuthenticated ? authLinks  : guestLinks }</Fragment>)}
    </Toolbar>
    </nav>
    )
};

MainAppBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(MainAppBar);
