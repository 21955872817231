import { GET_PAYOFFESTIMATES, PAYOFFESTIMATE_ERROR, CREATE_PAYOFFESTIMATE, PAYOFFESTIMATE_LOADING } from '../actions/types'

const initialState = {
    payoffestimates: [],
    estimateloading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_PAYOFFESTIMATES:
            return {
                ...state,
                payoffestimates: payload,
                error: null,
                estimateloading: false
            };
        case CREATE_PAYOFFESTIMATE:
        return {
            ...state,
            payoffestimate: payload,
            error: null,
            estimateloading: true
        };
        case PAYOFFESTIMATE_ERROR:
            return {
                ...state,
                error: payload,
                estimateloading: false
            };
        case PAYOFFESTIMATE_LOADING:
            return {
                ...state,
                error: null,
                estimateloading: true
            };
        default:
                return state;
    }

}