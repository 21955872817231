import { GET_DEBTS, DEBT_ERROR, UPDATE_DEBT, DELETE_DEBT } from '../actions/types'

const initialState = {
    debts: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_DEBTS:
            return {
                ...state,
                debts: payload,
                error: null,
                loading: false
            };
        case UPDATE_DEBT:
        return {
            ...state,
            debt: payload,
            error: null,
            loading: false
        };
        case DELETE_DEBT:
        return {
            ...state,
            debt: payload,
            error: null,
            loading: false
        };
        case DEBT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
                return state;
    }

}