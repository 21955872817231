import { GET_PRIORITYS, PRIORITY_ERROR, CREATE_PRIORITY, UPDATE_PRIORITY } from '../actions/types'

const initialState = {
    prioritys: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_PRIORITYS:
            return {
                ...state,
                prioritys: payload,
                error: null,
                loading: false
            };
        case CREATE_PRIORITY:
        return {
            ...state,
            priority: payload,
            error: null,
            loading: false
        };
        case UPDATE_PRIORITY:
        return {
            ...state,
            priority: payload,
            error: null,
            loading: false
        };
        case PRIORITY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
                return state;
    }

}