import { GET_BASICS, BASIC_ERROR, UPDATE_BASIC,DELETE_BASIC } from '../actions/types'

const initialState = {
    basics: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_BASICS:
            return {
                ...state,
                basics: payload,
                error: null,
                loading: false
            };
        case UPDATE_BASIC:
        return {
            ...state,
            basic: payload,
            error: null,
            loading: false
        };
        case DELETE_BASIC:
        return {
            ...state,
            basic: payload,
            error: null,
            loading: false
        };
        case BASIC_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
                return state;
    }

}