import { GET_PAYCHECKS, PAYCHECK_ERROR, UPDATE_PAYCHECK, DELETE_PAYCHECK } from '../actions/types'

const initialState = {
    paychecks: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_PAYCHECKS:
            return {
                ...state,
                paychecks: payload,
                error: null,
                loading: false
            };
        case UPDATE_PAYCHECK:
        return {
            ...state,
            paycheck: payload,
            error: null,
            loading: false
        };
        case DELETE_PAYCHECK:
        return {
            ...state,
            paycheck: payload,
            error: null,
            loading: false
        };
        case PAYCHECK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
                return state;
    }

}