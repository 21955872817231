import { GET_BILLS, BILL_ERROR, UPDATE_BILL, DELETE_BILL } from '../actions/types'

const initialState = {
    bills: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_BILLS:
            return {
                ...state,
                bills: payload,
                error: null,
                loading: false
            };
        case UPDATE_BILL:
        return {
            ...state,
            bill: payload,
            error: null,
            loading: false
        };
        case DELETE_BILL:
        return {
            ...state,
            bill: payload,
            error: null,
            loading: false
        };
        case BILL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
                return state;
    }

}