import { SET_ESTIMATERUNNING, REMOVE_ESTIMATERUNNING} from '../actions/types';

const initialState = {estimateRunning: false}


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_ESTIMATERUNNING:
            return {
                ...state,
                estimateRunning: true,
            };
        case REMOVE_ESTIMATERUNNING:
            return {
                ...state,
                estimateRunning: false,
            };
        default:
            return state;
    }
}