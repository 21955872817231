import {makeStyles} from '@material-ui/core/styles';

const drawerWidth = 200;

export default makeStyles((theme)=>({
    // appBar: {
    // //     // borderRadius: 15,
    // //     margin: '30px 0',
    // //     display: 'flex',
    // //     flexDirection: 'row',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   },
    //   heading: {
    //     color: 'green',
    //   },
      
    //   [theme.breakpoints.down('sm')]: {
    //     mainContainer: {
    //       flexDirection: 'column-reverse'
    //     }
    //   }
    root: {
      display: "flex"
    },
    
    // toolbar: {
    //   paddingRight: 24 // keep right padding when drawer closed
    // },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      // background: "green"
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    menuButtonHidden: {
      display: "none"
    },
    title: {
      flexGrow: 1
    },
    drawerPaper: {
      // position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      flexGrow: 1,
      height: "100vh",
      overflow: "auto"
    },
    container: {
      // paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(4),
      position: "relative",
      // backgroundColor: theme.palette.grey[900],
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      // margin: '30px 0',
    },
    fixedHeight: {
      height: 240
    },
    
      
}));