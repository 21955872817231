import React, { useState} from 'react';
import Link from "@material-ui/core/Link";
import Modal from '@material-ui/core/Modal';
import { TextField, Button, Typography, Paper } from '@material-ui/core';


import Website_Terms_of_Use from '../../Website_Terms_of_Use.pdf'
import Online_Privacy_Policy from '../../Online_Privacy_Policy.pdf'
import AllPagesPDFViewer from '../PDF/All-pages'
import SinglePagePDFViewer from '../PDF/Single-page'

import useStyles from './styles'

function Copyright() {

  const [termOpen, setTermOpen] = React.useState(false);
    const [policyOpen, setPolicyOpen] = React.useState(false);
   
    const handleTermOpen = () => {
        setTermOpen(true);
      };
      const handlePolicyOpen = () => {
        setPolicyOpen(true);
      };

      const handleClose = () => {
        setTermOpen(false);
        setPolicyOpen(false);
      };

  const classes = useStyles();


  return (
    <div className={classes.copy}>
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://usetrimtab.com/">
        Trimtab
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
    <Link className={classes.buttonView} onClick={() => window.location = 'mailto:info@usetrimtab.com'} >Contact Us</Link>
    <Link size="small" className={classes.buttonView} color="primary" variant="contained" 
                  onClick={(e) => (
                    handleTermOpen()
                  )}
                  >
                  View Terms of Use
                </Link>
                <Link size="small" className={classes.buttonView} color="primary" variant="contained" 
                  onClick={(e) => (
                    handlePolicyOpen()
                  )}
                  >
                  View Privacy Policy
                </Link>

                <div>
      <Modal
        className={classes.modal}
        open={termOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
      
        <div className={classes.modalpaper}>
        <AllPagesPDFViewer pdf={Website_Terms_of_Use} />
        </div>
      </Modal>
      </div>

      <div>
      <Modal
        className={classes.modal}
        open={policyOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalpaper}>
        <AllPagesPDFViewer pdf={Online_Privacy_Policy} />
        </div>
      </Modal>
      </div>
    </div>
  );
}

export default Copyright;