import { makeStyles } from '@material-ui/core/styles';


export default makeStyles((theme) => ({
 
    copy: {
        color: 'grey',
        margin: '60px',
        display: "flex",
        overflow: "auto",
        flexDirection: "row",
    },
    buttonView: {
        margin: '0px 30px',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      modalpaper: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        maxHeight: 500,
        //padding: 50,
        //width: 500,
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        margin: theme.spacing(1),
      //justifyContent: 'center',
      //alignItems: 'center',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },

}));