export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_REPOS = 'GET_REPOS';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const GET_PAYCHECKS = 'GET_PAYCHECKS';
export const PAYCHECK_ERROR = 'PAYCHECK_ERROR';
export const CREATE_PAYCHECK = 'CREATE_PAYCHECK';
export const UPDATE_PAYCHECK = 'UPDATE_PAYCHECK';
export const DELETE_PAYCHECK = 'DELETE_PAYCHECK';
export const GET_DEBTS = 'GET_DEBTS';
export const DEBT_ERROR = 'DEBT_ERROR';
export const CREATE_DEBT = 'CREATE_DEBT';
export const UPDATE_DEBT = 'UPDATE_DEBT';
export const DELETE_DEBT = 'DELETE_DEBT';
export const GET_BILLS = 'GET_BILLS';
export const BILL_ERROR = 'BILL_ERROR';
export const CREATE_BILL = 'CREATE_BILL';
export const UPDATE_BILL = 'UPDATE_BILL';
export const DELETE_BILL = 'DELETE_BILL';
export const GET_BASICS = 'GET_BASICS';
export const BASIC_ERROR = 'BASIC_ERROR';
export const CREATE_BASIC = 'CREATE_BASIC';
export const UPDATE_BASIC = 'UPDATE_BASIC';
export const DELETE_BASIC = 'DELETE_BASIC';
export const GET_PRIORITYS = 'GET_PRIORITYS';
export const PRIORITY_ERROR = 'PRIORITY_ERROR';
export const CREATE_PRIORITY = 'CREATE_PRIORITY';
export const UPDATE_PRIORITY = 'UPDATE_PRIORITY';
export const GET_PAYOFFESTIMATES = 'GET_PAYOFFESTIMATES';
export const PAYOFFESTIMATE_ERROR = 'PAYOFFESTIMATE_ERROR';
export const CREATE_PAYOFFESTIMATE = 'CREATE_PAYOFFESTIMATE';
export const PAYOFFESTIMATE_LOADING = 'PAYOFFESTIMATE_LOADING';
export const GET_METHODSELECTED = 'GET_METHODSELECTED';
export const METHODSELECTED_ERROR = 'METHODSELECTED_ERROR';
export const CREATE_METHODSELECTED = 'CREATE_METHODSELECTED';
export const UPDATE_METHODSELECTED = 'UPDATE_METHODSELECTED';
export const SET_PROGRESS = 'SET_PROGRESS';
export const REMOVE_PROGRESS = 'REMOVE_PROGRESS';
export const SET_ESTIMATERUNNING = 'SET_ESTIMATERUNNING';
export const REMOVE_ESTIMATERUNNING = 'REMOVE_ESTIMATERUNNING';