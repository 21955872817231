import React, { useEffect, Suspense, lazy } from 'react';
import {Grid} from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import './index.css';
import Alert from './Alert';


// import { useDispatch } from 'react-redux';

// import { getPosts } from './actions/posts';
// import trimtabLogoBlack from './images/trimtabLogoBlack.png';
// import trimtab_logo_white from './images/trimtab_logo_white.png';
// import Posts from './components/Posts/Posts';
// import Form from './components/Form/Form';
// import AccountForm from './components/Accounts/AccountForm'
import useStyles from './styles'
// import Welcome from './components/Welcome/Welcome'
// import Login from './components/Login/Login'
// import Register from './components/Register/Register'
// import StartStepper from './components/StartStepper/StartStepper'
// import Dashboard from './components/Dashboard/Dashboard'
// import Basics from './components/Basics/Basics';
// import Main from './components/Main/Main';
// import AppBarLanding from './components/AppBarLanding/AppBarLanding'
import Landing from './components/Landing/Landing'
import Copyright from './components/Copyright/Copyright'
import MainAppBar from './components/MainAppBar/MainAppBar'
// import NavTabs from './components/NavTabs/NavTabs'
// import Accounts from './components/Accounts/Accounts'
import PrivateRoute from './components/routing/PrivateRoute'

//redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken'
import Spinner from './Spinner';
// import PaymentHistory from './components/PaymentHistory/PaymentHistory';

if(localStorage.token) {
  setAuthToken(localStorage.token);
}

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Login = lazy(() => import('./components/Login/Login'));
const Register = lazy(() => import('./components/Register/Register'));
const StartStepper = lazy(() => import('./components/StartStepper/StartStepper'));
const NavTabs = lazy(() => import('./components/NavTabs/NavTabs'));
const Accounts = lazy(() => import('./components/Accounts/Accounts'));
const Debts = lazy(() => import('./components/Debts/Debts'));
const Basics = lazy(() => import('./components/Basics/Basics'));
const Bills = lazy(() => import('./components/Bills/Bills'));
const MethodSelect = lazy(() => import('./components/MethodSelect/MethodSelect'));
const PaymentHistory = lazy(() => import('./components/PaymentHistory/PaymentHistory'));


const App = () => { 

    const classes = useStyles();
      useEffect(() => {
        store.dispatch(loadUser());
      }, []);

    return (

      <Provider store={store}>
        <ThemeProvider theme={theme}>
        <Router>
        <MainAppBar></MainAppBar>
        <Alert />
       
        
         <div>
                    <Grid container  className={classes.container} justify="center" alignItems="stretch">
                    
                            <Switch>
                                {/* <Route exact path="/" component={Landing}></Route> */}
                                <Route exact path='/' component={() => { 
                                  window.location.href = 'https://www.usetrimtab.com'; 
                                  return null;
                                }}/>
                                <Suspense fallback={<Spinner></Spinner>}>
                                <Route exact path="/Login" component={Login}></Route>
                                <Route exact path="/Register" component={Register}></Route>
                                <PrivateRoute exact path="/StartHere" component={StartStepper}></PrivateRoute>
                                {/* <Route exact path="/Dashboard/*" component={Dashboard}></Route> */}
                                <PrivateRoute exact path="/Dashboard" component={NavTabs}></PrivateRoute>
                                <Route exact path="/PaymentHistory" component={PaymentHistory}></Route>
                                {/* <Route exact path="/Accounts" component={Accounts}></Route> */}
                                {/* <Route exact path="/Accounts" component={Accounts}></Route>
                                <Route exact path="/Basics" component={Basics}></Route> */}
                                </Suspense>
                            </Switch>
                    </Grid>
                    <Grid container  className={classes.container} justify="center" alignItems="stretch">
                        <Copyright />
                    </Grid>
        </div>
        </Router>
        </ThemeProvider>
      </Provider>
    );
}

export default App;