import { GET_METHODSELECTED, METHODSELECTED_ERROR, CREATE_METHODSELECTED, UPDATE_METHODSELECTED } from '../actions/types'

const initialState = {
    methodSelected: [],
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_METHODSELECTED:
            return {
                ...state,
                methodSelected: payload,
                error: null,
                loading: false
            };
        case CREATE_METHODSELECTED:
        return {
            ...state,
            methodSelected: payload,
            error: null,
            loading: false
        };
        case UPDATE_METHODSELECTED:
        return {
            ...state,
            methodSelected: payload,
            error: null,
            loading: false
        };
        case METHODSELECTED_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
                return state;
    }

}