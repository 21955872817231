import { SET_PROGRESS, REMOVE_PROGRESS} from '../actions/types';

const initialState = {progress: 0}


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_PROGRESS:
            return {
                ...state,
                progress: payload,
            };
        case REMOVE_PROGRESS:
            return {
                ...state,
                progress: true,
            };
        default:
            return state;
    }
}