import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import account from './account'
import paycheck from './paycheck';
import debt from './debt';
import bill from './bill'
import basic from './basic'
import priority from './priotity'
import payoffEstimate from './payoffEstimate'
import methodSelected from './methodSelected'
import progress from './progress';
import estimateRunning from './estimateRunning'

export default combineReducers({
    alert,
    auth,
    account,
    paycheck,
    debt,
    bill,
    basic,
    priority,
    payoffEstimate,
    methodSelected,
    progress,
    estimateRunning
})