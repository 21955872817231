import { makeStyles } from '@material-ui/core/styles';
// import MoneyEnvelope from '../../images/MoneyEnvelope.jpeg';
// import mapPhoto from '../../images/mapPhoto.jpeg';
// import receiptBox from '../../images/receiptBox.jpg';
import familyOnBeach from '../../images/familyOnBeach.jpg'

export default makeStyles((theme) => ({

    root: {
        backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      },

HeroContainer: {
    backgroundImage: `url(${familyOnBeach})`,
    backgroundColor: theme.palette.grey[900],
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '80vh',
    padding: 0,
    margin: 0,
    resizeMode: "stretch",
    opacity: '.5',

},

highlight: {
    // backgroundColor: 'white',
    // backgroundColor:'rgba(255,255,255,0)',
    padding: '30px',
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      position: 'absolute', 
      left: '50%', 
      top: '300px',
    transform: 'translate(-50%, -50%)',
    // maxWidth: 700,
    color: 'white',
    // opacity: '.5',
    
  },

  divider: {
    backgroundColor: 'white',
    margin: '10px',
  },

  subHeading: {
    color: 'secondary',
  },
 
 

}));